<div class="left-menu">
  <!-- <mat-card class="user-card">
    <mat-card-header>
      <button mat-icon-button (click)="closeSidenav()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-card-header>
    <mat-card-content>
      <div class="user-login">Zalogowany: {{ login }}</div>
      <div class="user-role">Typ konta: {{ role }}</div>
    </mat-card-content>
  </mat-card> -->


  <div class="user-section">
    <div class="user-info">
      <mat-icon class="user-avatar">account_circle</mat-icon>
      <div class="user-details">
        <p class="user-greeting">Witaj, {{ getUsername() }}!</p>
        <p class="user-role">{{ role }}</p>
        <p class="user-email">{{ login }}</p>
      </div>
    </div>
    <button mat-icon-button (click)="closeSidenav()">
      <mat-icon>close</mat-icon>
    </button>
  </div>  
  
  <div class="menu-buttons">
    <button mat-button routerLink="/points/map" (click)="closeSidenav()" class="menu-button">
      <mat-icon>map</mat-icon> Mapa
    </button>
    <button mat-button routerLink="/home" (click)="closeSidenav()" class="menu-button">
      <mat-icon>home</mat-icon> Home
    </button>
    <button mat-button routerLink="/points/counterparty" (click)="closeSidenav()" class="menu-button">
      <mat-icon>supervisor_account</mat-icon> Kontrahenci
    </button>
    <button mat-button routerLink="/points/offerList" (click)="closeSidenav()" class="menu-button">
      <mat-icon>local_offer</mat-icon> Oferty
    </button>
    <button mat-button routerLink="/points/pomoc" (click)="closeSidenav()" class="menu-button">
      <mat-icon>contact_support</mat-icon> Pomoc
    </button>
  </div>
</div>
