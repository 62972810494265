<mat-toolbar class="main-toolbar">
  <mat-toolbar-row>
    <div class="main-toolbar-left">
      <a class="logo" href="/">
        <div class="">
          <img class="logo-image" src="/assets/logo.png" />
        </div>
      </a>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<div class="page">
  <div class="right-half-screen-cover">
    <div class="form-div">
      <h2>Resetowanie hasła</h2>

      <div *ngIf="successMessage" class="success-message">
        {{ successMessage }}
      </div>

      <div *ngIf="errorMessage" class="error-message">
        {{ errorMessage }}
      </div>

      <form class="form-signin" (ngSubmit)="onSubmit()" #forgotPasswordForm="ngForm">
        <mat-form-field class="full-width">
          <input
            matInput
            name="email"
            type="email"
            class="form-control"
            placeholder="Adres e-mail"
            [(ngModel)]="email"
            required
            autofocus
            #emailField="ngModel"
          />
          <div *ngIf="emailField.invalid && (emailField.dirty || emailField.touched)" class="error">
            <div *ngIf="emailField.errors.required">Adres e-mail jest wymagany.</div>
            <div *ngIf="emailField.errors.email">Podaj prawidłowy adres e-mail.</div>
          </div>
        </mat-form-field>
        <div class="auth-buttons">
          <button class="btn-primary" type="submit" [disabled]="forgotPasswordForm.invalid">
            Wyślij link do resetowania hasła
          </button>
          <button type="button" (click)="toLogin()">Wróć do strony logowania</button>
        </div>
      </form>
    </div>
  </div>
</div>
