<mat-toolbar class="main-toolbar">
  <mat-toolbar-row>
    <div class="main-toolbar-left">
      <a class="logo" href="/">
        <div class="">
          <img class="logo-image" src="/assets/logo.png" />
        </div>
      </a>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<div class="page">
  <div class="right-half-screen-cover">
    <div class="form-div">
      <h1>Zaloguj się</h1>
      <h3 class="error" [hidden]="!errorMessage">{{ errorMessage }}</h3>
      <p id="profile-name" class="profile-name-card"></p>
      <form class="form-signin">
        <span id="reauth-email" class="reauth-email"></span>
        <mat-form-field class="full-width">
          <input
            matInput
            name="email"
            type="email"
            class="form-control"
            placeholder="Email"
            [(ngModel)]="email"
            required
            autofocus
          />
        </mat-form-field>
        <mat-form-field class="full-width">
          <input
            matInput
            name="password"
            type="password"
            class="form-control"
            placeholder="Hasło"
            [(ngModel)]="password"
            required
          />
        </mat-form-field>
        <div class="auth-buttons">
          <button class="btn-primary" type="submit" (click)="login()">
            Zaloguj
          </button>
          <!-- <button (click)="toRegister()">
            Nie masz konta? Zarejestruj się!
          </button> -->
          <button (click)="toResetPassword()">Nie pamiętasz hasła?</button>
        </div>
      </form>
    </div>
  </div>
</div>
