import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../../../../assets/styles/shared/auth.scss'],
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  email: string;
  token: string;
  errorMessage: string;
  successMessage: string;
  loaderId = "reset-password-loader";

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private toastrService: ToastrService,
    private ngxService: NgxUiLoaderService,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.email = params['email'];
      this.token = params['token'];
    });

    this.resetPasswordForm = this.fb.group({
      email: [this.email, [Validators.required, Validators.email]],
      token: [this.token, Validators.required],
      newPassword: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).+$')
      ]],
      confirmPassword: ['', Validators.required]
    }, { validator: this.passwordMatchValidator });
  }

  passwordMatchValidator(form: FormGroup) {
    return form.get('newPassword').value === form.get('confirmPassword').value
      ? null : { mismatch: true };
  }

  onSubmit() {

    this.ngxService.startBackgroundLoader(this.loaderId);

    if (this.resetPasswordForm.invalid) {
      return;
    }

    const { email, token, newPassword } = this.resetPasswordForm.value;

    this.authService.resetPassword(email, token, newPassword).subscribe(
      data => {
        this.successMessage = 'Hasło zostało pomyślnie zmienione.';
        this.toastrService.success(this.successMessage, "");
        this.ngxService.stopBackgroundLoader(this.loaderId);
        this.successMessage = null;
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 3000);
      },
      error => {
        this.errorMessage = error.error.message || 'Błąd resetowania hasła.';
        this.toastrService.success(this.errorMessage, "");
        this.ngxService.stopBackgroundLoader(this.loaderId);
        this.errorMessage = null;
      }
    );
  }

  toLogin() {
    this.router.navigate(['/login']);
  }
}
