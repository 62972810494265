import { BehaviorSubject } from "rxjs";
import { MapInvestment } from "../points/models/investment";
import { FilterPreset } from "@src/app/points/models/Settings/FilterPreset";

export class MapState {
  markers = new BehaviorSubject<Array<google.maps.Marker>>([]);
  userMarker = new BehaviorSubject<google.maps.Marker>(null);
  mainWindowMap: google.maps.Map;

  selectedMarker: BehaviorSubject<google.maps.Marker> = new BehaviorSubject(
    null
  );
  selectedInvestment: BehaviorSubject<MapInvestment> = new BehaviorSubject(
    null
  );

  infoWindowStatus = new BehaviorSubject(InfoWindowStatusEnum.none);
  infoWindowTab = new BehaviorSubject(InfoWIndowTab.default);
  isFollowingUser = new BehaviorSubject(true);
  canAddPoints = new BehaviorSubject(false);
  canAddOffer = new BehaviorSubject(false);

  //navigation
  navigationList = new BehaviorSubject<Array<MapInvestment>>([]);

  //
  mapCenter = new google.maps.LatLng(52.06938, 19.48014);
  zoom = 8;
  infoWindowHeight = new BehaviorSubject(0);
  selectedFilter = new BehaviorSubject<FilterPreset>(undefined);
}

export enum InfoWIndowTab {
  default,
  offer,
  calendar,
  notes,
  history,
}

export enum InfoWindowStatusEnum {
  none,
  small,
  editable,
}
