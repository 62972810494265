<ngx-ui-loader [loaderId]="'forgot-password-loader'"></ngx-ui-loader>

<mat-toolbar class="main-toolbar">
  <mat-toolbar-row>
    <div class="main-toolbar-left">
      <a class="logo" href="https://navi-bud.pl">
        <div class="">
          <img class="logo-image" src="/assets/logo.png" alt="Nawigacja po inwestycjach budowlanych" />
        </div>
      </a>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<div class="background">
  <div class="pulse-point point-gray" style="top: 25%; left: 9%;"></div>
  <div class="pulse-point point-orange" style="top: 5%; left: 40%;"></div>
  <div class="pulse-point point-blue" style="top: 50%; left: 30%;"></div>
  <div class="pulse-point point-orange" style="top: 70%; left: 20%;"></div>
</div>
<div class="page">
  <div class="right-half-screen-cover">
    <div class="form-div">
      <h2>Resetowanie hasła</h2>
      <form class="form-signin" (ngSubmit)="onSubmit()" #forgotPasswordForm="ngForm">
        <mat-form-field class="full-width">
          <input
            matInput
            name="email"
            type="email"
            class="form-control"
            placeholder="Adres e-mail"
            [(ngModel)]="email"
            required
            autofocus
            #emailField="ngModel"
          />
          <div *ngIf="emailField.invalid && (emailField.dirty || emailField.touched)" class="error">
            <div *ngIf="emailField.errors.required">Adres e-mail jest wymagany.</div>
            <div *ngIf="emailField.errors.email">Podaj prawidłowy adres e-mail.</div>
          </div>
        </mat-form-field>
        <div class="auth-buttons">
          <button class="btn-primary" type="submit" [disabled]="forgotPasswordForm.invalid">
            Wyślij link do resetowania hasła
          </button>
          <button type="button" (click)="toLogin()">Wróć do strony logowania</button>
        </div>
      </form>
    </div>
  </div>
</div>
