<mat-divider></mat-divider>
<div class="investition-details">
  <div class="invest-data">
    <div class="heading-box">
      <mat-icon aria-hidden="false" aria-label="Dane inwestycji" fontIcon="apartment"></mat-icon>
      <h2>Dane inwestycji</h2>
    </div>
    <mat-form-field>
      <input matInput name="intentType" type="text" placeholder="Zamierzenie budowlane"
        [(ngModel)]="investment.intentType" required autofocus />
    </mat-form-field>

    <mat-form-field>
      <img matPrefix class="small-icon-image" src="{{ getBuildingStatus() }}" />
      <mat-select placeholder="Status prac budowlanych" name="workStatus" [(ngModel)]="investment.buildingStatus"
        (selectionChange)="setIcon()">
        <mat-option class="building-status" *ngFor="let status of objectKeys(workStatuses)"
          [value]="workStatuses[status]">
          <img class="small-icon-image" src="/assets/markers/default/{{ workStatuses[status] }}.svg" />
          {{ status }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <input matInput name="permissionDate" type="date" placeholder="Data zezwolenia"
        [ngModel]="investment.permissionDate | date: 'yyyy-MM-dd'" (ngModelChange)="investment.permissionDate = $event"
        disabled />
    </mat-form-field>

    <mat-form-field>
      <input matInput name="administrationBody" type="text" placeholder="Organ wydajcy zezwolenie"
        [(ngModel)]="investment.administrationBody" disabled />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Ogrodzenie</mat-label>
      <mat-select [(value)]="investment.isFenced" name="fanceType">
        <mat-option *ngFor="let fanceType of fanceTypes" [value]="fanceType">
          {{ fanceType }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <input matInput name="street" type="text" placeholder="Ulica" [(ngModel)]="investment.streetName" autofocus />
    </mat-form-field>

    <mat-form-field>
      <input matInput name="city" type="text" placeholder="Miasto" [(ngModel)]="investment.city" autofocus />
    </mat-form-field>

    <mat-form-field>
      <input matInput name="postCode" type="text" placeholder="Kod pocztowy" [(ngModel)]="investment.postcode"
        autofocus />
    </mat-form-field>

    <mat-form-field>
      <input matInput name="permissionNumber" type="text" placeholder="Nr. zezwolenia"
        [(ngModel)]="investment.permissionNumber" disabled />
    </mat-form-field>
  </div>
  <mat-divider class="show-on-mobile"></mat-divider>
  <div class="selling-data">
    <div class="heading-box">
      <mat-icon aria-hidden="false" aria-label="Dane sprzedażowe" fontIcon="work_outline"></mat-icon>
      <h2>Dane sprzedażowe</h2>
    </div>

    <mat-form-field>
      <img matPrefix class="small-icon-image" [src]="getSellStatus()" />
      <mat-select placeholder="Status punktu" name="pointStatus" [(ngModel)]="investment.sellStatus"
        (selectionChange)="setIcon()">
        <mat-option class="point-status" *ngFor="let status of objectKeys(pointStatuses)"
          [value]="pointStatuses[status]">
          <img class="small-icon-image" src="/assets/statuses/{{ pointStatuses[status] }}.svg" />
          {{ status }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Data następnego kontaktu</mat-label>
      <input matInput [(ngModel)]="investment.nextContactDate" [matDatepicker]="picker" name="nextContactDate" />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <div class="sell-chances-section">
      <mat-label>{{ getSellChancesHeader() }}</mat-label>
      <mat-checkbox [(ngModel)]="sellChancesDisabled" name="sellChancesDisabled"></mat-checkbox>
    </div>
    <mat-slider [disabled]="sellChancesDisabled" [max]="getSellChancesLength()" [min]="1" [step]="1" [discrete]="true"
      [showTickMarks]="true">
      <input matSliderThumb [(ngModel)]="investment.sellChances" #slider>
    </mat-slider>
    <mat-form-field>
      <input matInput name="products" type="text" placeholder="Produkty" [(ngModel)]="investment.products" autofocus />
    </mat-form-field>

    <mat-form-field>
      <input matInput name="resignationReaason" type="text" placeholder="Powód rezygnacji"
             [(ngModel)]="investment.resignationReason" autofocus />
    </mat-form-field>
    <div class="action-buttons">
      <hr />
      <button (click)="delete()" title="Usuń punkt" color="warn">
        <mat-icon>delete_forever</mat-icon>Usuń punkt
      </button>
    </div>
  </div>
</div>
