import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { OnDestroyBase } from '../../../common/OnDestroyBase';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['../../../../assets/styles/shared/auth.scss'],
})
export class ForgotPasswordComponent extends OnDestroyBase {
  email = '';
  errorMessage: string;
  successMessage: string;

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {
    super();
  }

  onSubmit() {
    if (!this.email) {
      this.errorMessage = 'Musisz podać prawidłowy adres e-mail.';
      this.successMessage = null;
      return;
    }
    this.subscriptions.push(
      this.authService.forgotPassword(this.email).subscribe(
        (data) => {
          this.successMessage = 'Link do resetowania hasła został wysłany na Twój adres e-mail.';
          this.errorMessage = null;
         
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 3000);
        },
        (error) => {
          this.errorMessage = 'Błąd podczas wysyłania linku do resetowania hasła.';
          this.successMessage = null;
        }
      )
    );
  }

  toLogin() {
    this.router.navigate(['/login']);
  }
}
