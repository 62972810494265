
<ngx-ui-loader [loaderId]="'login-loader'"></ngx-ui-loader>
<mat-toolbar class="main-toolbar">
  <mat-toolbar-row>
    <div class="main-toolbar-left">
      <a class="logo" href="https://navi-bud.pl">
        <div class="">
          <img class="logo-image" src="/assets/logo.png" alt="Nawigacja po inwestycjach budowlanych" />
        </div>
      </a>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<div class="background">
  <div class="pulse-point point-gray" style="top: 25%; left: 9%;"></div>
  <div class="pulse-point point-orange" style="top: 5%; left: 40%;"></div>
  <div class="pulse-point point-blue" style="top: 50%; left: 30%;"></div>
  <div class="pulse-point point-orange" style="top: 70%; left: 20%;"></div>
</div>
<div class="page">

  <div class="right-half-screen-cover">
    <div class="form-div">
      <h1>Zaloguj się</h1>
      <form class="form-signin">
        <span id="reauth-email" class="reauth-email"></span>
        <mat-form-field class="full-width">
          <input
            matInput
            name="email"
            type="email"
            class="form-control"
            placeholder="Email"
            [(ngModel)]="email"
            required
            autofocus
          />
        </mat-form-field>
        <mat-form-field class="full-width">
          <input
            matInput
            name="password"
            type="password"
            class="form-control"
            placeholder="Hasło"
            [(ngModel)]="password"
            required
          />
        </mat-form-field>
        <div class="auth-buttons">
          <button class="btn-primary" type="submit" (click)="login()">
            Zaloguj
          </button>
          <!-- <button (click)="toRegister()">
            Nie masz konta? Zarejestruj się!
          </button> -->
          <button (click)="toResetPassword()">Nie pamiętasz hasła?</button>
        </div>
      </form>
    </div>
  </div>
</div>
