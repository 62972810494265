import { NgModule, Injectable, Injector } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AppMaterialModule } from "./app.material";
import { appRoutes } from "./app.routes";
import { LoginComponent } from "./auth/containers/login/login.component";
import { AuthGuard } from "./auth/guards/auth.guard";
import { AuthService } from "./auth/services/auth.service";
import { UserService } from "./auth/services/user.service";
import { MainMenuItemComponent } from "./core/components/main-menu-item/main-menu-item.component";
import { MainMenuComponent } from "./core/components/main-menu/main-menu.component";
import { AppComponent } from "./core/containers/app/app.component";
import { HomeComponent } from "./core/containers/home/home.component";
import { MainLayoutComponent } from "./core/containers/main-layout/main-layout.component";
import { PageNotFoundComponent } from "./core/containers/page-not-found/page-not-found.component";
import { LayoutService } from "./core/services/layout.service";
import { MapService } from "./points/containers/map/map.service";
import { RouteRepository } from "./points/dataAccess/routeRepository";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DragulaModule } from "ng2-dragula";
import { NaviPointsListService } from "./points/navigation/services/navi-points-list.service";
import { MatNativeDateModule } from "@angular/material/core";
import { HttpConfigInterceptor } from "./core/interceptor/httpconfig.interceptor";
import { ToastrModule } from "ngx-toastr";
import { RegisterComponent } from "./auth/containers/register/register.component";
import { ForgotPasswordComponent } from "./auth/containers/forgotPassword/forgot-password.component";
import { ResetPasswordComponent } from "./auth/containers/resetPassword/reset-password.component";

import * as Hammer from "hammerjs";
import {
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from "@angular/platform-browser";
import { PendingChangesGuard } from "./points/services/pending-changes.service";
import { NgxUiLoaderModule, NgxUiLoaderConfig, POSITION } from "ngx-ui-loader";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NavibudCalendarModule } from "./navibud-calendar/navibud-calendar.module";
import { StoreModule } from "@ngrx/store";
import { NgrxFormsModule } from "ngrx-forms";
import { RootReducer } from "@src/app/State/RootReducer";
import { MatInputModule } from "@angular/material/input";
import { EffectsModule } from "@ngrx/effects";
import { AppEffects } from "@src/app/points/windows/info-tabs/offer/effect-offer";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { InvestmentEffects } from "@src/app/points/services/effect-investment";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { DoughnutChartComponent } from "./core/containers/charts/doughnut-chart/doughnut-chart.component";
import { LineChartComponent } from "./core/containers/charts/line-chart/line-chart.component";
import { BuildingStatusesChartComponent } from "./core/containers/charts/building-statuses-chart/building-statuses-chart.component";
import { UserDataComponent } from "./core/containers/user/user-data/user-data.component";
import { UserPasswordComponent } from "./core/containers/user/user-password/user-password.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { ServiceLocator } from "@src/utils/service/ServiceLocator";
import { ConfirmEmailComponent } from './core/containers/confirm-email/confirm-email.component';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsPosition: POSITION.centerCenter,
  bgsOpacity: 0.7,
  bgsSize: 100,
  bgsColor: "#ff7f11"
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    MainMenuItemComponent,
    PageNotFoundComponent,
    MainLayoutComponent,
    MainMenuComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    DoughnutChartComponent,
    LineChartComponent,
    BuildingStatusesChartComponent,
    UserDataComponent,
    UserPasswordComponent,
    ConfirmEmailComponent,
  ],
  imports: [
    StoreModule.forRoot(RootReducer, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictStateSerializability: false,
        strictActionImmutability: false,
        strictActionSerializability: false,
        strictActionTypeUniqueness: false,
        strictActionWithinNgZone: false,
      },
    }),
    EffectsModule.forRoot([AppEffects, InvestmentEffects]),
    NgrxFormsModule,
    NavibudCalendarModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    BrowserModule,
    MatInputModule,
    AppMaterialModule,
    MatFormFieldModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    RouterModule.forRoot(appRoutes, {
      enableTracing: false,
      onSameUrlNavigation: "reload",
    }),
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    DragulaModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: "toast-bottom-right",
      preventDuplicates: true,
    }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [
    AuthGuard,
    AuthService,
    LayoutService,
    UserService,
    MapService,
    NaviPointsListService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    RouteRepository,
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    PendingChangesGuard,
  ],
  bootstrap: [AppComponent],
  exports: [BrowserModule, BrowserAnimationsModule],
})
export class AppModule {
  constructor(private injector: Injector) {
    ServiceLocator.injector = this.injector;
  }
}
