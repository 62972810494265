<mat-toolbar class="main-toolbar">
  <mat-toolbar-row>
    <div class="main-toolbar-left">
      <a class="logo" href="/">
        <div class="">
          <img class="logo-image" src="/assets/logo.png" />
        </div>
      </a>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<div class="page">
  <div class="right-half-screen-cover">
    <div class="form-div">
      <h2>Resetowanie hasła</h2>

      <div *ngIf="successMessage" class="success-message">
        {{ successMessage }}
      </div>

      <div *ngIf="errorMessage" class="error-message">
        {{ errorMessage }}
      </div>

      <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
        <mat-form-field class="full-width">
          <input
            matInput
            placeholder="Nowe hasło"
            formControlName="newPassword"
            type="password"
            required
          />
          <div *ngIf="resetPasswordForm.get('newPassword').invalid && (resetPasswordForm.get('newPassword').dirty || resetPasswordForm.get('newPassword').touched)" class="error">
            <div *ngIf="resetPasswordForm.get('newPassword').errors.required">Nowe hasło jest wymagane.</div>
            <div *ngIf="resetPasswordForm.get('newPassword').errors.minlength">Hasło musi mieć co najmniej 6 znaków.</div>
            <div *ngIf="resetPasswordForm.get('newPassword').errors.pattern">Hasło musi zawierać co najmniej jedną dużą literę, jedną małą literę i jedną cyfrę.</div>
          </div>
        </mat-form-field>
        <mat-form-field class="full-width">
          <input
            matInput
            placeholder="Potwierdź nowe hasło"
            formControlName="confirmPassword"
            type="password"
            required
          />
          <div *ngIf="resetPasswordForm.get('confirmPassword').invalid && (resetPasswordForm.get('confirmPassword').dirty || resetPasswordForm.get('confirmPassword').touched)" class="error">
            <div *ngIf="resetPasswordForm.get('confirmPassword').errors.required">Potwierdzenie hasła jest wymagane.</div>
          </div>
        </mat-form-field>
        <div *ngIf="resetPasswordForm.errors?.mismatch" class="error">
          Nowe hasło i jego potwierdzenie nie są takie same.
        </div>
        <div class="auth-buttons">
          <button class="btn-primary" type="submit" [disabled]="resetPasswordForm.invalid">
            Zmień hasło
          </button>
          <button type="button" (click)="toLogin()">Wróć do strony logowania</button>
        </div>
      </form>
    </div>
  </div>
</div>
