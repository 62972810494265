import { Component } from "@angular/core";
import { UserService } from "../../../auth/services/user.service";
import { MapService } from "../../../points/containers/map/map.service";
import { sessionService } from "src/app/core/services/session.service";
import { FilterService } from "src/app/points/windows/filter/filter.service";
import { Router } from "@angular/router";
import { OnDestroyBase } from "@src/app/common/OnDestroyBase";
import { LayoutService } from "../../services/layout.service";
import { SyncService } from "@src/app/points/dataAccess/sync/sync.service";
import Dexie from "dexie";
import { NavibudDbName } from "@src/app/points/dataAccess/repositoryBase";
import {storageService} from "@src/app/services/storageService";

@Component({
  selector: "app-main-menu",
  templateUrl: "./main-menu.component.html",
  styleUrls: ["./main-menu.component.css"],
})
export class MainMenuComponent extends OnDestroyBase {
  mapLinksVisible: boolean;

  constructor(
    private userService: UserService,
    private mapService: MapService,
    public filterService: FilterService,
    private router: Router,
    private layoutService: LayoutService,
    private syncService: SyncService
  ) {
    super();
    this.subscriptions.push(
      router.events.subscribe((url: any) => {
        this.mapLinksVisible = this.router.url.indexOf("map") > -1;
      })
    );
  }

  get login(): string {
    return this.userService.user.login;
  }

  get role(): string {
    return this.userService.user.role;
  }

  async logout() {
    await storageService.cleanup();
    sessionService.logoutEndOfSession();
  }

  showNavi() {
    // this.filterService.clearFilterFlag();
    // this.pointEditorService.clearPointEditorFlag();
    this.mapService.showRouteEditor();
  }

  showGhost() {
    this.mapService.switchUserPath();
  }

  showFilters() {
    this.mapService.clearNaviFlag();
    // this.pointEditorService.clearPointEditorFlag();
    this.filterService.toggleWindow();
  }

  closeSidenav() {
    this.layoutService.closeSidenav();
  }

  getUsername(): string {
    const login = this.userService.user?.login;
    
    if (login && login.includes('@')) {
      return login.split('@')[0];
    }
    
    return login || '(nieznany)';
  }
  
}
