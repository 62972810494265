import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { IsFencedEnum } from "../../models/enums";
import { MapInvestment } from "../../models/investment";
import { PointStatus } from "../../models/point-status.enum";
import { Icon } from "../../models/icon.enum";
import { INaviStore } from "../../../State/RootReducer";
import { deleteInvestmentEffect } from "../../services/effect-investment";

@Component({
  selector: "app-investment-info",
  templateUrl: "./investment-info.component.html",
  styleUrls: ["./investment-info.component.scss"],
})
export class InvestmentInfoComponent implements OnInit {
  @Input() investment: MapInvestment;
  sellChances = [1, 2, 3, 4, 5, 6];
  sellChancesDisabled = true;
  fanceTypes = Object.values(IsFencedEnum).map((x) => x.toLowerCase());
  autoTicks = false;
  showTicks = false;
  tickInterval = 1;
  pointStatuses: {};
  workStatuses = {};
  constructor(private store: Store<INaviStore>) {

    this.workStatuses = {};
    for (let item in Icon) {
      if (Icon[item] !== Icon.User) {
        this.workStatuses[Icon[item]] = Icon[item].toLowerCase().replace(/ /g, "_");
      }
    }
    this.pointStatuses = {};

    for (let item in PointStatus) {
      if (PointStatus.hasOwnProperty(item)) {
        this.pointStatuses[PointStatus[item]] = PointStatus[item].toLowerCase().replace(/ /g, "_");
      }
    }
  }

  getSellStatus() {
    return this.toSellStatus(this.investment.sellStatus);
  }

  toSellStatus(pointStatus: string) {
    return `/assets/statuses/${this.formatString(pointStatus)}.svg`;
  }

  getBuildingStatus() {
    return this.toBuildingStatus(this.investment.buildingStatus);
  }

  toBuildingStatus(str: string) {
    const result = `/assets/markers/default/${this.formatString(str)}.svg`;
    return result;
  }

  private formatString(str: string) {
    return str.replace(/ /g, "_").trim().replace(".svg", "").toLowerCase();
  }

  setIcon() {
    // Functions.setDefaultMarkerIcon(this.selectedMarker);
  }

  ngOnInit(): void {}

  getSellChancesLength() {
    return this.sellChances.length;
  }

  delete() {
    if (confirm("Czy na pewno chcesz usunać wybrany punkt?")) {
      this.store.dispatch(
        deleteInvestmentEffect({ investments: [this.investment] })
      );
    }
  }

  getSellChancesHeader() {
    return this.sellChancesDisabled
      ? `Szanse sprzedażowe (${this.investment.sellChances}/${this.sellChances.length})`
      : "Szanse sprzedażowe (aktywne)";
  }

  getSliderTickInterval(): number | "auto" {
    if (this.showTicks) {
      return this.autoTicks ? "auto" : this.tickInterval;
    }

    return 0;
  }

  objectKeys(obj: any) {
    return Object.keys(obj);
  }
}
